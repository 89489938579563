@import "../node_modules/react-grid-layout/css/styles.css";
@import "../node_modules/react-resizable/css/styles.css";

@font-face {
    font-family: "SecuritasPro-Light";
    font-display: swap;
    font-weight: 400;
    src: url("./assets/fonts/securitas/SecuritasPro-Light.otf") format("opentype");
}

.spotlightAppGuide {
    position: relative;
    z-index: 102;
}

input:focus-visible,
button:focus-visible {
    outline: 4px solid #8d5fff;
    outline-offset: -2px;
}

a:focus-visible,
[role="button"]:focus-visible,
[role="link"]:focus-visible,
[role="option"]:focus-visible,
button:not([color="accent"]):not([color="tertiary"]):not([color="error"]):focus-visible {
    outline: 4px solid #8d5fff;
    border-radius: 1px;
    outline-offset: 1px;
}
